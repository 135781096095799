import About from "./components/About/About";
import "./App.css";

function App() {
  return (
    <div id="top" className="app">
      <main>
        <About />
      </main>
    </div>
  );
}

export default App;

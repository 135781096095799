// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
    flex-direction: column;
    margin-top: 3em;
  }
  
  .about__name {
    color: var(--clr-primary);
  }
  
  .about__role {
    margin-top: 1.2em;
  }
  
  .about__desc {
    white-space: pre-line;
    font-size: 1rem;
    max-width: 600px;
  }
  
  .about__desc,
  .about__contact {
    margin-top: 2.4em;
  }
  
  .about .link--icon {
    margin-right: 0.8em;
  }
  
  .about .btn--outline {
    margin-right: 1em;
  }
  
  @media (max-width: 600px) {
    .app .about {
      align-items: flex-start;
      margin-top: 2em;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,eAAe;IACf,gBAAgB;EAClB;;EAEA;;IAEE,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE;MACE,uBAAuB;MACvB,eAAe;IACjB;EACF","sourcesContent":[".about {\n    flex-direction: column;\n    margin-top: 3em;\n  }\n  \n  .about__name {\n    color: var(--clr-primary);\n  }\n  \n  .about__role {\n    margin-top: 1.2em;\n  }\n  \n  .about__desc {\n    white-space: pre-line;\n    font-size: 1rem;\n    max-width: 600px;\n  }\n  \n  .about__desc,\n  .about__contact {\n    margin-top: 2.4em;\n  }\n  \n  .about .link--icon {\n    margin-right: 0.8em;\n  }\n  \n  .about .btn--outline {\n    margin-right: 1em;\n  }\n  \n  @media (max-width: 600px) {\n    .app .about {\n      align-items: flex-start;\n      margin-top: 2em;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const header = {
    // all the properties are optional
    homepage: 'http://joshuagalindo.com',
    title: 'JG.',
  }
  
  const about = {
    // all the properties are optional
    name: 'Joshua Galindo',
    role: 'Backend Cloud Developer',
    description:
      'I\'m a backend cloud developer dedicated to crafting and refining the\
      compute APIs that serve as the foundation for creating virtual machines. My \
      focus lies in designing and enhancing these essential interfaces, enabling \
      the seamless construction and operation of virtual machines within the cloud environment. \
      \n\nI\'m using my free time to work on projects I\'ve been wanting to \
      get around to. Starting with this personal site for myself. As my main focus revolves around \
      shaping the backend infrastructure, these side projects will allow me to explore other sides of development, \
      that I don\'t normally get to work on allowing me to cultivate fresh ideas and broaden my expertise. \
      \n\nI\'m gearing up to expand this website over the next few months, and I\'ll also be sharing the \
      challenges I face and the solutions I discover along the way.',
    resume: 'https://example.com',
    social: {
      linkedin: 'https://linkedin.com',
      github: 'https://github.com',
    },
  }
  
  export { header, about }
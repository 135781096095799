// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

:root {
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #90a0d9;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.center {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,oBAAoB;EACpB,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;EACtB;uCACqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".app {\n  font-family: 'Poppins', sans-serif;\n  line-height: 1.5;\n  color: var(--clr-fg);\n  background-color: var(--clr-bg);\n}\n\n:root {\n  --clr-bg: #23283e;\n  --clr-bg-alt: #2a2f4c;\n  --clr-fg: #bdbddd;\n  --clr-fg-alt: #cdcdff;\n  --clr-primary: #90a0d9;\n  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,\n    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n}\n\nmain {\n  max-width: 1100px;\n  width: 95%;\n  margin: 0 auto;\n}\n\n.center {\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { about } from "../../details";
import "./About.css";

function About() {
  const { name, role, description } = about;
  return (
    <div className="about center">
      {name && (
        <h1>
          Hi, I'm <span className="about__name">{name}.</span>
        </h1>
      )}

      {role && <h2 className="about__role">A {role}.</h2>}
      <p className="about__desc">{description && description}</p>
    </div>
  );
}

export default About
